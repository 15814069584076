.prose {
  color: var(--black);
  max-width: none !important;
  overflow: auto;
}
.prose :is(h1, h2, h3, h4, h5, h6) {
  color: var(--black);
  margin-bottom: 0;
}
.prose :is(h1, h2, h3, h4) {
  font-weight: bold;
}
.prose h2,
.prose h2 strong {
  font-size: 2.25rem;
}

.prose h3,
.prose h3 strong {
  font-size: 1.75rem;
}

.prose h4,
.prose h4 strong {
  font-size: 1.5rem;
}

.prose blockquote {
  border-left-color: var(--primary);
}

.prose ul li::marker {
  color: var(--primary);
}

.prose .text-align-center {
  text-align: center;
}

.prose iframe.media-oembed-content {
  width: 100%;
}

/**
 * TABLES
 */
.prose table {
  table-layout: auto;
  font-size: 1rem;
}

.prose table thead :is(th, td),
.prose table tbody :is(th, td) {
  padding: 1rem;
  border: 1px solid rgb(229 231 235);
}

.prose table tbody :is(th, td) {
  font-weight: normal;
}

.prose table thead :is(th, td) {
  color: var(--white);
  background: var(--primary);
}

.prose table tbody tr:nth-child(odd) {
  background: var(--white);
}
.prose table tbody tr:nth-child(even) {
  background: rgb(243 244 246);
}

.article-full .prose img {
  margin: auto;
  padding-top: .5rem;
  padding-bottom: .5rem;
}
.prose strong {
  color: inherit;
}
.prose a {
  text-decoration: none;
  color: var(--primary);
  font-weight: normal;
}
.prose a:hover {
  text-decoration: underline;
}

.bg-primary .prose a,
.bg-primary .prose a * {
  color: var(--white) !important;
}

@media screen and (max-width:768px){
  .prose table {
    display: block;
    max-width: 100%;
    max-height: 700px;
    overflow: scroll;
  }
  .prose table thead {
    position: sticky;
    top: 0;
  }
  .prose iframe.media-oembed-content {
    height: 100%;
    min-height: 300px;
  }
}
