form.ao-form {
  padding: 0 !important;
  margin: 0 !important;
}

form.ao-form .ao-recaptcha-error-wrapper.ao-recaptcha-error {
  padding: 0 !important;
}

form.ao-form .ao-required {
  display: none;
}

form.ao-form .ao-column {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

form.ao-form select,
form.ao-form textarea,
form.ao-form input[type=text] {
  width: 100% !important;
  border-color: rgb(229 231 235 / 1) !important;
  padding: 0.5rem 1rem;
  border-width: 1px;
  border-radius: 0.375rem;
}

form.ao-form .ao-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
}

form.ao-form .ao-row:last-of-type {
  justify-content: flex-end;
}

form.ao-form .ao-column-6 {
  width: calc(50% - 1rem) !important;
}

form.ao-form .ao-combo-layout.horizontal {
  padding: .5rem 0;
}

form.ao-form .ao-column-6 .ao-column-inner > div {
  width: 100% !important;
  flex-grow: 1;
}

form.ao-form .ao-submit-block > div {
  display: flex;
  justify-content: right;
}

form.ao-form button.ao-form-submit {
  font-weight: 700 !important;
  font-size: 20px !important;
  padding: 8px 24px !important;
  border: 2px solid #00AEEF !important;
  background: #00AEEF !important;
  transition: all .5s ease-in-out;
}

form.ao-form button.ao-form-submit:hover {
  background: #fff !important;
  color: #00AEEF !important;
}

/* Hide the existing/new customer selector */
#block-b1664815172345 {
  display: none;
}

/* Make the final row comment field span full width. */
#row-r1664826991500 > .ao-column:nth-of-type(2) {
  width: 100% !important;
}
#row-r1664826991500 > .ao-column:nth-of-type(2) textarea {
  min-height: 150px;
}

@media screen and (max-width: 768px)  {
  form.ao-form .ao-column-6 .ao-column-inner > div {
    width: 100% !important;
  }
  form.ao-form .ao-row {
    gap: 0 !important;
  }
  form.ao-form .ao-column-6 {
    width: 100% !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}